import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route} from 'react-router-dom';
import './index.css';
import './styles/flex-border.css'
import { faBook, faCloud, faPaperPlane} from '@fortawesome/free-solid-svg-icons';
import App from './App';
import Nav from './components/navigation/Nav';
import Upload from './components/upload/Upload';
import Library from './components/library/Library';
import Login from './components/navigation/Login/Login';
import DocumentDownload from './components/documents/DocumentDownload'
import Dashboard from './components/dashboard/Dashboard';
import Contact from './components/contact/Contact';
import Profile from './components/profile/Profile';

import * as serviceWorker from './serviceWorker';


/*
    Index.JS handles the routes to different pages

*/
ReactDOM.render(
    <BrowserRouter>
        <div className="flex-border-column" onClick={
            event =>{
            //Handle menu clicked globally across entire app.
            //if its the Bootstrap hamburger menu object, display menu
            //otherwise, do nothing.
                if(event.target.className === "navbar-toggler-icon" || event.target.className==="navbar-toggler")
                    document.getElementById("navbarColor03").style.display="flex"  
                else{
                    if(event.target.className==="collapse navbar-collapse flex-border-row-centered")
                    {}
                    else
                        document.getElementById("navbarColor03").style.display= "none"
                }
            }
        }>
            <Nav links = {[
                {
                    route: "/library",
                    icon: {faBook},
                    label: "Library"
                },
                {
                    route: "/upload",
                    icon: {faCloud},
                    label: "Upload"
                },
                {
                    route: "/contact",
                    icon: {faPaperPlane},
                    label: "Contact"
                }
            ]}
            /> 
            <Route exact path="/" component={App}></Route>
            <Route exact path="/upload" component={Upload}></Route>
            <Route exact path="/library" component={Library}></Route>
            <Route exact path="/search/:searchID" component={Library} />
            <Route exact path="/login" component={Login}></Route>
            {/* :document is generated by the database */}
            <Route exact path="/library/:document" component={DocumentDownload}></Route>
            <Route exact path="/dashboard" component={Dashboard}></Route>
            <Route exact path="/contact" component={Contact}></Route>
            <Route exact path="/profile/:userProfileID" component={Profile}></Route>
        </div>
    </BrowserRouter>
    , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();


import React, {Component} from 'react';
import './RadioButton.css';

//import firebase



//import React Components




class RadioButton extends Component {

  constructor(props){
    super(props);
    this.state={
      
    }
  }

  componentDidMount(){

  }
  render(){
    return (
      <div class="form-group">


   
      </div>
  
    
    );
  }
  
}

export default RadioButton;


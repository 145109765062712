
import * as firebase from 'firebase';
const firebaseConfig = {
    apiKey: "AIzaSyCOe3eMAY2nwPqggS9kJQ6EeJCawTQTgaE",
    authDomain: "appletree-58949.firebaseapp.com",
    databaseURL: "https://appletree-58949.firebaseio.com",
    projectId: "appletree-58949",
    storageBucket: "appletree-58949.appspot.com",
    messagingSenderId: "993854316222",
    appId: "1:993854316222:web:1d2408bd0cc28d9c"
};

firebase.initializeApp(firebaseConfig);

export default firebaseConfig;

